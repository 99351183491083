import { DialogActions, DialogContent, IconButton, useMediaQuery, useTheme } from '@mui/material'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { useFormik, FormikProvider, Form } from 'formik'
import WarningIcon from '@mui/icons-material/Warning'
import React, { useEffect, useState } from 'react'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import Tooltip from '@mui/material/Tooltip'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import { Base64 } from 'js-base64'

import { useAddCooperationMutation, useEditCooperationMutation } from 'shared/store/useWholesalers'
import { WholesalerType, AddWholesalerPayload } from 'shared/store/useWholesalers/types'
import { WholesalersModalInitialValues } from 'views/Authorized/views/Wholesalers/types'
import ContainedButton from 'shared/components/Buttons/ContainedButton'
import OutlinedButton from 'shared/components/Buttons/OutlinedButton'
import mapDeliveryTypes from 'shared/utils/helpers/mapDeliveryTypes'
import invalidateCarts from 'shared/utils/helpers/invalidateCarts'
import SelectField from 'shared/components/SelectField'
import RadioField from 'shared/components/RadioButton'
import InputField from 'shared/components/InputField'
import { ParametersEnum } from 'shared/consts'
import { Yup } from 'shared/lib'
import i18n from 'locales/i18n'

import schema from './schema'

type Props = {
   wholesalerInfo?: WholesalerType
   activeLocationUuid?: string
   closeModal: () => void
}

type Option = {
   name: string
   value: string
}

type ParameterType = {
   [key: string]: {
      required: boolean
      hideInput: boolean
      name: string
      tooltip: Nullable<string>
      defaultValue: Nullable<string>
      password: boolean
   }
}

export default function WholesalerInfoModal({ wholesalerInfo, closeModal, activeLocationUuid }: Props) {
   const { t } = useTranslation(['TRANSLATION', 'WHOLESALERS'])
   const [departments, setDepartments] = useState<Option[]>([])
   const [updatedSchema, setUpdatedSchema] = useState<Yup.ObjectSchema<any>>()
   const queryClient = useQueryClient()
   const { mutate: mutateAdd, isLoading: isLoadingAdd } = useAddCooperationMutation()
   const [parameters, setParameters] = useState<ParameterType>()
   const theme = useTheme()
   const isMobile = useMediaQuery(theme.breakpoints.down('lg'))
   const { mutate: mutateEdit, isLoading: isLoadingEdit } = useEditCooperationMutation()

   const paymentOptions = [
      { value: 'FV', label: 'FV' },
      { value: 'WZ', label: 'WZ' },
   ]

   const currencyOptions = [
      { value: 'PLN', name: 'PLN' },
      { value: 'EUR', name: 'EUR' },
      { value: 'CZK', name: 'CZK' },
   ]

   const formik = useFormik<WholesalersModalInitialValues>({
      initialValues: {
         loginParameter:
            wholesalerInfo?.parameters.LOGIN?.defaultValue &&
            Base64.isValid(wholesalerInfo?.parameters.LOGIN?.defaultValue)
               ? Base64.decode(wholesalerInfo?.parameters.LOGIN?.defaultValue)
               : '',
         passwordParameter:
            wholesalerInfo?.parameters.PASSWORD?.defaultValue &&
            Base64.isValid(wholesalerInfo?.parameters.PASSWORD?.defaultValue)
               ? Base64.decode(wholesalerInfo?.parameters.PASSWORD?.defaultValue)
               : '',
         additionalParameter:
            wholesalerInfo?.parameters.ADDITIONAL_PARAMETER?.defaultValue &&
            Base64.isValid(wholesalerInfo?.parameters.ADDITIONAL_PARAMETER?.defaultValue)
               ? Base64.decode(wholesalerInfo?.parameters.ADDITIONAL_PARAMETER?.defaultValue)
               : '',
         firstDepartment: '',
         secondDepartment: '',
         minLogisticsCurrency: 'PLN',
         thirdDepartment: '',
         deliveryDepartment: '',
         deliveryType: '',
         logisticMinimums: 0,
         fv: false,
         wz: false,
         paymentsMethod: '',
         cooperationType: 'CLIENT_ONLY',
      },
      validationSchema: updatedSchema,
      onSubmit: handleSubmit,
   })

   const { setValues, values, setFieldValue, isValid } = formik

   function handleSubmit() {
      if (activeLocationUuid) {
         const deliveryDepartments = [
            values.firstDepartment ? values.firstDepartment : '',
            values.secondDepartment ? values.secondDepartment : '',
            values.thirdDepartment ? values.thirdDepartment : '',
         ]

         const newSettings: AddWholesalerPayload = {
            additionalParameter: values.additionalParameter ? Base64.encode(values.additionalParameter) : null,
            deliveryDepartment: values.deliveryDepartment,
            minLogisticsCurrency: values.minLogisticsCurrency,
            departmentUuids: deliveryDepartments,
            afterSalesBonus: values.afterSalesBonus || null,
            deliveryType: values.deliveryType,
            login: values.loginParameter ? Base64.encode(values.loginParameter) : '',
            orderDocumentType: values.paymentsMethod || 'DEFAULT',
            wholesaleUuid: wholesalerInfo?.wholesaleUuid ? wholesalerInfo.wholesaleUuid : '',
            password: values.passwordParameter ? Base64.encode(values.passwordParameter) : '',
            minLogistics: values.logisticMinimums,
         }
         if (
            wholesalerInfo?.cooperationStatus === 'UPDATE_PENDING' ||
            wholesalerInfo?.cooperationStatus === 'ACCEPTED' ||
            wholesalerInfo?.cooperationStatus === 'REJECTED' ||
            wholesalerInfo?.cooperationStatus === 'EXPIRED' ||
            wholesalerInfo?.cooperationStatus === 'PENDING'
         ) {
            mutateEdit(
               {
                  locationUuid: activeLocationUuid,
                  cooperationUuid: wholesalerInfo.cooperationUuid,
                  payload: newSettings,
               },
               {
                  onSuccess: () => {
                     closeModal()
                     invalidateCarts(queryClient)
                     queryClient.invalidateQueries('wholesalers')
                     queryClient.invalidateQueries('minimums')
                  },
               }
            )
         } else {
            mutateAdd(
               { locationUuid: activeLocationUuid, payload: newSettings },
               {
                  onSuccess: () => {
                     closeModal()
                     invalidateCarts(queryClient)
                     queryClient.invalidateQueries('wholesalers')
                     queryClient.invalidateQueries('minimums')
                  },
               }
            )
         }
      }
   }

   function onPasswordVisibilityChange(key: string, visibility: boolean) {
      if (parameters) {
         const newParameters = {
            ...parameters,
            [key]: {
               ...parameters[key],
               password: visibility,
            },
         }
         setParameters(newParameters)
      }
   }

   function renderInputAdornment(key: string) {
      const input = parameters![key]
      if (input) {
         if (input.password === true) {
            return (
               <IconButton onClick={() => onPasswordVisibilityChange(key, false)}>
                  <VisibilityIcon />
               </IconButton>
            )
         }
         return (
            <IconButton onClick={() => onPasswordVisibilityChange(key, true)}>
               <VisibilityOffIcon />
            </IconButton>
         )
      }
      return null
   }

   async function initFormik() {
      if (wholesalerInfo) {
         const options: Option[] = [{ value: '', name: '' }]

         wholesalerInfo?.departments
            .filter((item) => item.departmentUuid !== '')
            .map((option) => options.push({ value: option.departmentUuid, name: option.name }))

         setDepartments(options)

         if (wholesalerInfo.cooperationStatus === 'ACCEPTED' || wholesalerInfo.cooperationStatus === 'UPDATE_PENDING') {
            await new Promise(() => {
               setValues({
                  firstDepartment:
                     wholesalerInfo.departments.find((item) => item.type === 'FIRST')?.departmentUuid || '',
                  secondDepartment:
                     wholesalerInfo.departments.find((item) => item.type === 'SECOND')?.departmentUuid || '',
                  thirdDepartment:
                     wholesalerInfo.departments.find((item) => item.type === 'THIRD')?.departmentUuid || '',
                  deliveryDepartment: wholesalerInfo.departments.find(
                     (item) => item.departmentUuid === wholesalerInfo.deliveryDepartment
                  )?.departmentUuid,
                  additionalParameter: wholesalerInfo.additionalParameter
                     ? Base64.decode(wholesalerInfo.additionalParameter)
                     : '',
                  afterSalesBonus: wholesalerInfo.afterSalesBonus || 0,
                  deliveryType: wholesalerInfo.deliveryType || 'DEFAULT',
                  loginParameter: wholesalerInfo.login ? Base64.decode(wholesalerInfo.login) : '',
                  passwordParameter: wholesalerInfo.password ? Base64.decode(wholesalerInfo.password) : '',
                  logisticMinimums: wholesalerInfo.minLogistics || 0,
                  minLogisticsCurrency: wholesalerInfo.minLogisticsCurrency,
                  paymentsMethod: wholesalerInfo.orderDocumentType,
                  fv: wholesalerInfo?.orderDocumentType === 'FV',
                  wz: wholesalerInfo?.orderDocumentType === 'WZ',
                  cooperationType: wholesalerInfo?.cooperationType,
               })
            })
         } else if (wholesalerInfo) {
            await new Promise(() => {
               setValues({
                  ...formik.values,
                  additionalParameter: wholesalerInfo.additionalParameter
                     ? Base64.decode(wholesalerInfo.additionalParameter)
                     : '',
                  deliveryType: wholesalerInfo.deliveryType || 'DEFAULT',
                  thirdDepartment: wholesalerInfo.defaultDepartments?.thirdDepartmentUuid || '',
                  firstDepartment: wholesalerInfo.defaultDepartments?.firstDepartmentUuid || '',
                  secondDepartment: wholesalerInfo.defaultDepartments?.secondDepartmentUuid || '',
                  deliveryDepartment: wholesalerInfo.defaultDepartments?.deliveryDepartmentUuid || '',
               })
            })
         }
         if (wholesalerInfo.departments.length === 1) {
            const mainDepartment = wholesalerInfo.departments[0].departmentUuid
            setFieldValue('thirdDepartment', mainDepartment)
            setFieldValue('deliveryDepartment', mainDepartment)
         }
      }
   }

   useEffect(() => {
      const selectedDeps = [values.firstDepartment, values.secondDepartment, values.thirdDepartment]

      if (!selectedDeps.includes(values.deliveryDepartment)) {
         setFieldValue('deliveryDepartment', '')
      }
   }, [values])

   useEffect(() => {
      initFormik()
      if (wholesalerInfo?.parameters) {
         const newParameters: ParameterType = {}
         Object.entries(wholesalerInfo.parameters).map(
            // eslint-disable-next-line no-return-assign
            ([key, value]) =>
               (newParameters[key] = {
                  ...value,
                  password: value.hideInput,
               })
         )

         setParameters(newParameters)
      }
   }, [wholesalerInfo])

   useEffect(() => {
      if (wholesalerInfo) {
         let newSchema = schema
         Object.entries(wholesalerInfo.parameters).forEach(([key, value]) => {
            if (key === 'LOGIN') {
               if (value.required) {
                  newSchema = newSchema.shape({
                     loginParameter: Yup.string().required(),
                  })
               }
            } else if (key === 'PASSWORD') {
               if (value.required) {
                  newSchema = newSchema.shape({
                     passwordParameter: Yup.string().required(),
                  })
               }
            } else if (key === 'ADDITIONAL_PARAMETER') {
               if (value.required) {
                  newSchema = newSchema.shape({
                     additionalParameter: Yup.string().required(),
                  })
               }
            }
         })
         setUpdatedSchema(newSchema)
      }
   }, [wholesalerInfo?.parameters])

   function renderParameteres() {
      if (parameters) {
         return Object.entries(parameters).map(([key, value]) => (
            <Tooltip title={value.tooltip || ''}>
               <div style={{ width: '100%' }}>
                  <InputField
                     required={value.required}
                     InputProps={{
                        endAdornment: value.hideInput ? <Box>{renderInputAdornment(key)}</Box> : null,
                     }}
                     name={ParametersEnum[key as keyof typeof ParametersEnum]}
                     variant="standard"
                     type={value.password === false ? 'text' : 'password'}
                     label={value.name}
                  />
               </div>
            </Tooltip>
         ))
      }
      return null
   }

   return (
      <>
         <DialogContent>
            <FormikProvider value={formik}>
               {wholesalerInfo && (
                  <Form>
                     <Typography variant="body1" sx={{ whiteSpace: 'pre-wrap', mt: 1, mb: 4, color: 'text.secondary' }}>
                        {wholesalerInfo?.description && wholesalerInfo.description[i18n.language]
                           ? wholesalerInfo?.description[i18n.language]
                           : t('WHOLESALERS:editWholesalerDialog')}
                     </Typography>
                     <Stack direction="column" spacing={{ xs: 3, md: 4 }}>
                        <Stack
                           sx={{ pl: { lg: 2, md: 2, xs: 1 } }}
                           direction={{ xs: 'column', md: 'row' }}
                           alignItems="flex-end"
                           spacing={{ xs: 2, md: 3 }}
                        >
                           {renderParameteres()}
                        </Stack>
                        <Stack
                           sx={{ pl: { lg: 2, md: 2, xs: 1 } }}
                           direction={{ xs: 'column', md: 'row' }}
                           spacing={{ xs: 2, md: 3 }}
                        >
                           <SelectField
                              searcher
                              name="firstDepartment"
                              label={t('WHOLESALERS:firstDep')}
                              options={departments.filter(
                                 (item) =>
                                    (item.value !== formik.values.secondDepartment &&
                                       item.value !== formik.values.thirdDepartment) ||
                                    item.value === ''
                              )}
                              maxRows={5}
                           />
                           <SelectField
                              searcher
                              name="secondDepartment"
                              label={t('WHOLESALERS:secondDep')}
                              options={departments.filter(
                                 (item) =>
                                    (item.value !== formik.values.firstDepartment &&
                                       item.value !== formik.values.thirdDepartment) ||
                                    item.value === ''
                              )}
                              maxRows={5}
                           />
                           <SelectField
                              searcher
                              name="thirdDepartment"
                              label={t('WHOLESALERS:thirdDep')}
                              options={departments.filter(
                                 (item) =>
                                    (item.value !== formik.values.firstDepartment &&
                                       item.value !== formik.values.secondDepartment) ||
                                    item.value === ''
                              )}
                              maxRows={5}
                           />
                        </Stack>
                        <Stack
                           sx={{ pl: { lg: 2, md: 2, xs: 1 } }}
                           direction={{ xs: 'column', md: 'row' }}
                           spacing={{ xs: 2, md: 3 }}
                        >
                           <SelectField
                              required
                              name="deliveryDepartment"
                              label={t('WHOLESALERS:deliveryDep')}
                              options={departments.filter(
                                 (item) =>
                                    item.value === formik.values.firstDepartment ||
                                    item.value === formik.values.secondDepartment ||
                                    item.value === formik.values.thirdDepartment ||
                                    item.value === ''
                              )}
                              maxRows={5}
                           />
                           <InputField
                              fullWidth={false}
                              required
                              variant="standard"
                              name="logisticMinimums"
                              type="Number"
                              label={t('TRANSLATION:formFields.logisticMinimums')}
                              inputProps={{
                                 min: 0,
                              }}
                           />
                           <SelectField noMinWidth label="" name="minLogisticsCurrency" options={currencyOptions} />
                           {wholesalerInfo.documents.includes('FV') && wholesalerInfo.documents.includes('WZ') ? (
                              <div style={{ marginTop: 7, display: 'flex' }}>
                                 <RadioField options={paymentOptions} name="paymentsMethod" />
                              </div>
                           ) : null}
                        </Stack>
                        <Stack
                           sx={{ pl: { lg: 2, md: 2, xs: 1 } }}
                           direction={{ xs: 'column', md: 'row' }}
                           spacing={{ xs: 2, md: 3 }}
                        >
                           {wholesalerInfo.deliveryTypes && Object.entries(wholesalerInfo.deliveryTypes).length > 0 && (
                              <SelectField
                                 options={mapDeliveryTypes(wholesalerInfo.deliveryTypes)}
                                 label={t('TRANSLATION:formFields.deliveryType')}
                                 name="deliveryType"
                              />
                           )}
                           <InputField
                              fullWidth={false}
                              type="Number"
                              variant="standard"
                              name="afterSalesBonus"
                              label={t('WHOLESALERS:afterSalesBonus')}
                              inputProps={{
                                 min: 0,
                                 step: 0.1,
                              }}
                           />
                        </Stack>
                        {wholesalerInfo.wholesaleMessage && (
                           <Box sx={{ pl: { lg: 2, md: 2, xs: 1 } }}>
                              <TextField
                                 variant="outlined"
                                 disabled
                                 multiline
                                 fullWidth={isMobile}
                                 sx={{ minWidth: { lg: 300, md: 300, xs: 'auto' } }}
                                 value={wholesalerInfo.wholesaleMessage}
                                 label="Warunki dostawy i płatności"
                              />
                           </Box>
                        )}
                     </Stack>
                     {wholesalerInfo.cooperationType === 'CLIENT_ONLY' && (
                        <Typography variant="body2" sx={{ mt: 3 }}>
                           <WarningIcon sx={{ mb: -0.5, mr: 0.5, color: 'orange' }} />{' '}
                           {t('WHOLESALERS:clientOnlyWarning')}
                        </Typography>
                     )}
                  </Form>
               )}
            </FormikProvider>
         </DialogContent>
         <DialogActions>
            <OutlinedButton variant="outlined" onClick={closeModal}>
               {t('TRANSLATION:cancel')}
            </OutlinedButton>
            <ContainedButton
               disabled={!isValid || isLoadingAdd || isLoadingEdit}
               onClick={handleSubmit}
               variant="contained"
               type="submit"
            >
               {t('TRANSLATION:save')}
            </ContainedButton>
         </DialogActions>
      </>
   )
}
